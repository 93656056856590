#achievements .box-info {
  padding-left: 0rem !important;
  margin-top: 20px;
  overflow: scroll;
}
#achievements .box-info div {
  padding: 10px;
  background: var(--light);
  border-radius: 20px;
  align-items: center;
}

#achievements .box-info .box-info-head h5 {
  display: inline;
  font-size: 18px;
  font-weight: 600;
  color: var(--dark);
  padding-left: 20px;
}
#achievements .box-info .box-info-footer {
  float: right;
}
#achievements .box-info .box-info-footer .fa {
  margin: 0px 5px;
  color: var(--dark);
}

#achievements .achievement-form .box-info-head {
  display: flex;
}

#achievements input,
#achievements textarea,
#achievements input:focus,
#achievements textarea:focus {
  outline: none;
  border: 0px;
  color: inherit;
  background: inherit;
  font-size: inherit;
  font-weight: inherit;
}
#achievements input::placeholder,
#achievements textarea::placeholder {
  color: inherit;
}

#achievements h5 input,
#achievements li textarea,
#achievements .text-bg-warning input {
  width: 100% !important;
}

#achievements .box-info-footer button {
  background: none;
  color: var(--dark);
  border: 0px;
}

#achievements .achievement-form input[name="year"] {
  display: inline;
  width: 30px;
}

#achievements .achievement-form textarea[name="title"] ,#achievements .achievement-form h5{
  width: 100%;
}


