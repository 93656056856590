#certificates .box-info {
  padding-left: 0rem !important;
  margin-top: 20px;
  overflow: scroll;
}
#certificates .box-info div {
  padding: 10px;
  background: var(--light);
  border-radius: 20px;
  align-items: center;
}

#certificates .box-info .box-info-head h5 {
  font-size: 18px;
  font-weight: 600;
  color: var(--dark);
}
#certificates .box-info .box-info-head .badge:nth-child(1) {
  float: right;
}
#certificates .box-info .box-info-body p {
  margin-bottom: 0px;
  color: var(--dark);
  font-weight: 500;
  font-size: smaller;
  margin: 5px 0px;
}

#certificates .box-info .box-info-body .badge {
  margin-right: 5px;
}

#certificates .box-info-footer {
  float: right;
}
#certificates .box-info .box-info-footer .fa {
  margin: 0px 5px;
  color: var(--dark);
}

#certificates input,
#certificates textarea,
#certificates input:focus,
#certificates textarea:focus {
  outline: none;
  border: 0px;
  color: inherit;
  background: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin-left: 5px;
}
#certificates input::placeholder,
#certificates textarea::placeholder {
  color: inherit;
}

#certificates h5 input,
#certificatesli textarea,
#certificates .text-bg-warning input {
  width: 100% !important;
}

#certificates .box-info-footer button {
  background: none;
  color: var(--dark);
  border: 0px;
}

input[name="issued_by"] {
  width: 70%;
}
